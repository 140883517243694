// 增加题库界面
<template>
<!--    <div>增加题库界面</div>-->
    <section class="add">
    <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="活动名称">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
        </el-form-item>
    </el-form>
    </section>
</template>

<script>
    export default {
        name: "addExamStore",
        data(){
            return{
                form:{
                    name: '',
                    uid: this.$cookies.get("user").id
                }
            }
        },
        methods:{
            onSubmit(){
                // console.log(this.name) ;
                if(this.form.name == '' || this.form.name == null){
                    //如果没有新增的题库的名称，这提示报错
                    this.$message.error("题库的名称不能为空") ;
                    return ;
                }
                this.$axios({
                    url: '/addExamStore',
                    method: 'post',
                    params:{
                        uid:this.form.uid,
                        name:this.form.name
                    }
                }).then(res=>{
                    if(res.code == 200){
                    //     进行跳转
                        this.$message.success(res.message) ;
                        this.$router.push('/examStore') ;
                    }else{
                    //     提示错误信息
                        this.$message.error(res.message)  ;
                    }
                }) ;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .add {
        padding: 0px 40px;
        width: 400px ;
    }
</style>